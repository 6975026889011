const getInitials = (admin: any) => {
    const firstName = admin?.firstname || admin?.firstName;
    const lastName = admin?.lastname || admin?.lastName;
    return firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase();
}

export const recognitionListInfo = [
    {
        dataField: "avatar",
        editable: false,
        text: "Avatar",
        formatter: (_: any, admin: any) => (
            <div className="rounded-circle header-profile-user initials">
                {getInitials(admin)}
            </div>
        ),
    },
    {
        dataField: "userName",
        text: "Staff Name",
        sort: true,
    },
    {
        dataField: "siteCode",
        text: "Site Code",
        sort: true,
    },
    {
        dataField: "recognition.createdPost",
        text: "Total Posts",
        sort: true,
    },
    {
        dataField: "recognition.like",
        text: "Total Likes",
        sort: true,
    },
    // {
    //     dataField: "hendyScore.clickings",
    //     text: "Clickings",
    //     sort: true,
    // },
    {
        dataField: "recognition.comment",
        text: "Total comments",
        sort: true,
    },
    {
        dataField: "recognition.share",
        text: "Total Shares",
        sort: true,
    },
    {
        dataField: "recognition.pin",
        text: "Total Pins",
        sort: true,
    },
    {
        dataField: "menu",
        editable: false,
        text: "Action",
    }
]