import { useState, useEffect } from "react";
import { getAllPosts, getPostsByAdminId, getPostById } from "src//helpers/backend_helper";
import { IPost } from "src/interfaces/general";

const usePosts = (companyCode?: string) => {

    const [posts, setPosts] = useState<Array<IPost>>([])
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorAlert, setErrorAlert] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchAllPosts = () => {
        setLoading(true);
        if (companyCode) {
            getAllPosts()
                .then(res => {
                    if (res.success) {
                        setPosts(res.result || []);
                    }
                }).catch(console.log).finally(() => setLoading(false))
        }
    }
    const fetchPostsByAdminId = (staffId: string) => {
        setLoading(true);
        if (staffId) {
            getPostsByAdminId(staffId)
                .then(res => {
                    if (res.success) {
                        setPosts(res.result || []);
                    }
                }).catch(console.log).finally(() => setLoading(false))
        }
    }

    const getPostByPostId = async (postId: string): Promise<IPost | undefined> => {
        try {
            setLoading(true);
            const res = await getPostById(postId);
            if (res.success) {
                console.log('getPostByPostId', res.result);
                return res.result;
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            console.error('Error:', error);
            return undefined;
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAllPosts()
    }, []);

    return {
        posts,
        loading,
        success,
        errorAlert,
        errorMessage,
        fetchPostsByAdminId,
        getPostByPostId
    };
}

export default usePosts;