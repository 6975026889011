import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getSiteByDocId, updateSiteByDocId } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, CardTitle, FormGroup, Input, Label } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";
import AvatarEdit from "src/components/Common/AvatarEdit";
import ColorInput from "src/components/Common/ColorInput";
import Dropzone from "react-dropzone";
import { storage } from "../../helpers/firebase_config";
const EditSite = (props: any) => {
  const dispatch = useDispatch();
  const { docid } = useParams<any>();
  const history = useHistory();
  const [logo, setLogo] = useState("");
  const [color, setColor] = useState("");
  const [homeVideURL, setHomeVideoURL] = useState("");
  const [videoUploading, setVideoUploading] = useState<boolean>(false);
  const [services, setServices] = useState({
    buy: false,
    sell_my_car: false,
    insurance: false,
    rental: false,
    tyres: false,
    parts: false,
    parking: false,
    fix_my_car: false,
    charge_points: false,
    my_carbon: false,
    games: false,
    finance: false,
    car_warranty: false,
    gap_insurance: false,
    service_plan: false,
    protect: false,
  })

  const { signleSite, loading } = useSelector((state: any) => state.site);

  useEffect(() => {
    dispatch(getSiteByDocId(docid));
  }, []);

  useEffect(() => {
    if(signleSite?.services) {
      setServices(signleSite?.services)
    } else {
      setServices({
        buy: false,
        sell_my_car: false,
        insurance: false,
        rental: false,
        tyres: false,
        parts: false,
        parking: false,
        fix_my_car: false,
        charge_points: false,
        my_carbon: false,
        games: false,
        finance: false,
        car_warranty: false,
        gap_insurance: false,
        service_plan: false,
        protect: false,
      })
    }
    setHomeVideoURL(signleSite.home_video_url)
    setLogo(signleSite.logo);
    setColor(signleSite.color);
  }, [signleSite]);

  const handleEditSite = (values: any) => {
    values.services = services;
    values.logo = logo;
    values.color = color;
    values.home_video_url = homeVideURL
    dispatch(updateSiteByDocId(docid, values, history));
  };

  const handleServiceCheck = (e: any) => {
    setServices({...services, [e.target.name]: e.target.checked})
  }

  const onChange = (logo:string)=> {
    setLogo(logo)
  }; 

  const onChangeColor = (color:string) => {
    setColor(color)
  };

  const handleAcceptedVideoFiles = async (files: any) => {
    setVideoUploading(true);
    const path = `/home_video_url/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setVideoUploading(false);
    setHomeVideoURL(url)
  };

  const handleChangeVideo = (e: any) =>{
    setHomeVideoURL(e.target.value)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Site</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/sites" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Site Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: SiteCode: {signleSite?.code}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditSite(values);
            }}
          >           
            <Row>
              <Col md="6">
                <AvatarEdit 
                  singleSelect={signleSite?.logo}            
                  onChange={onChange}
                  title={"Site"}
                />
              </Col>
              <Col md="6">
                <CardTitle>Home video URL</CardTitle>
                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedVideoFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h6>Drop files here or click to upload.</h6>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews" id="file-previews">
                  {videoUploading && (
                    <div className="d-flex justify-content-center">
                      <i
                        className="bx bx-loader bx-spin align-middle me-2"
                        style={{ fontSize: 46 }}
                      ></i>
                    </div>
                  )}
                  <div className="pt-3 pb-3">
                    <FormGroup>
                      <Label for="image">Or Please enter video URL.</Label>
                      <Input
                        name="home_video_url"
                        onChange={handleChangeVideo}
                        value={homeVideURL}
                      />
                    </FormGroup>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="code"
                    label="Site Code"
                    placeholder="Site code should 7 digits"
                    type="string"
                    errorMessage="Should be 7 digits"
                    validate={{
                      required: { value: true },
                      minLength: {
                        value: 7,
                        errorMessage: "Min 7 chars.",
                      },
                      maxLength: {
                        value: 7,
                        errorMessage: "Max 7 chars.",
                      },
                    }}
                    value={signleSite?.code || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.name || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Phone"
                    type="text"
                    errorMessage="Invalid Phone number"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.phone || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.email || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    errorMessage="Invalid Password"
                    validate={{
                      required: { value: true },
                    }}
                    value={"" || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="address"
                    label="Address"
                    type="text"
                    errorMessage="Invalid Address"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.address || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="postcode"
                    label="Postcode"
                    type="text"
                    errorMessage="Invalid postcode"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.postcode || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="websiteUrl"
                    label="Website Url"
                    type="text"
                    errorMessage="Invalid websiteUrl"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.websiteUrl || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="bookingUrl"
                    label="Booking Url"
                    type="text"
                    errorMessage="Invalid bookingUrl"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.bookingUrl || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="tutorialVideo"
                    label="Tutorial Video"
                    type="text"
                    errorMessage="Invalid tutorialVideo"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.tutorialVideo || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Row>
                <Col md="6">
                  <h5>Location</h5>
                </Col>
              </Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="location.lat"
                    label="Lat"
                    type="number"
                    errorMessage="Invalid lat"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.location?.lat || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="location.lon"
                    label="Lon"
                    type="number"
                    errorMessage="Invalid lon"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.location?.lon || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="managerName"
                    label="Manager Name"
                    type="text"
                    errorMessage="Invalid ManagerName"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.managerName || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <label> Color </label>
                  <ColorInput
                    value={signleSite.color}
                    onChangeColor={onChangeColor}  
                    isModal={true}
                  />               
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="spincarId"
                    label="Spincar ID"
                    type="text"
                    errorMessage="Invalid Spincar ID"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.spincarId || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="dealerId"
                    label="WAS Dealer ID"
                    type="text"
                    errorMessage="Invalid WAS Dealer ID"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleSite?.dealerId || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
                <h4>Directories</h4>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="buy" checked = {services?.buy} onChange = {(e) => handleServiceCheck(e)}/>
                  <label className="form-check-label">
                      Buy
                  </label>
                </div>  

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="sell_my_car" checked = {services?.sell_my_car} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Sell My Car
                    </label>
                </div>
  
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="insurance" checked = {services?.insurance} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                        Insurance
                    </label>
                </div>
                
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="rental" checked = {services?.rental} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Rental
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="tyres" checked = {services?.tyres} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">Tyres</label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="parts" checked = {services?.parts} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Parts
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="parking" checked = {services?.parking} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                    Parking
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="fix_my_car" checked = {services?.fix_my_car} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                        Fix My Car
                    </label>
                </div>

                
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="charge_points" checked = {services?.charge_points} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Charge Points
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="my_carbon" checked = {services?.my_carbon} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      My Carbon
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="games" checked = {services?.games} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Games
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="finance" checked = {services?.finance} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      finance
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="car_warranty" checked = {services?.car_warranty} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Car Warranty
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="gap_insurance" checked = {services?.gap_insurance} onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Gap Insurance
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="service_plan" checked = {services?.service_plan } onChange = {(e) => handleServiceCheck(e)}/>
                    <label className="form-check-label">
                      Service Plan
                    </label>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="protect" checked = {services?.protect } onChange = {(e) => handleServiceCheck(e)}/>
                  <label className="form-check-label">
                    Protect
                  </label>
                </div>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}> 
                  {loading ? (
                      <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 24}}></i>
                      ) : (
                        <>Update</>
                      )}
                    
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditSite);
