const getPostLikesLength = (post: any) => {
    return post.likes?.length
}
const getPostPinsLength = (post: any) => {
    return post.pins?.length
}
const getPostCommentsLength = (post: any) => {
    return post.pins?.length
}
const getPostSharesLength = (post: any) => {
    return post.shares?.length
}
const getDateCreated = (post: any): string => {
    if (!post?.createdAt) {
        return '-';
    }

    try {
        const date = new Date(post.createdAt);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    } catch (error) {
        console.error('Error formatting date:', error);
        return '-';
    }
};
export const recognitionListInfoPosts = [
    {
        dataField: "title",
        text: "Post",
        sort: true,
    },
    {
        text: "Total Likes",
        sort: true,
        formatter: (_: any, post: any) => (
            <div className="">
                {getPostLikesLength(post)}
            </div>
        ),
    },
    {
        text: "Total comments",
        sort: true,
        formatter: (_: any, post: any) => (
            <div className="">
                {getPostCommentsLength(post)}
            </div>
        ),
    },
    {
        text: "Total Shares",
        sort: true,
        formatter: (_: any, post: any) => (
            <div className="">
                {getPostSharesLength(post)}
            </div>
        ),
    },
    {
        text: "Total Pins",
        sort: true,
        formatter: (_: any, post: any) => (
            <div className="">
                {getPostPinsLength(post)}
            </div>
        ),
    },
    {
        text: "Date Created",
        sort: true,
        formatter: (_: any, post: any) => (
            <div className="">
                {getDateCreated(post)}
            </div>
        ),
    },
    {
        editable: false,
        text: "Action",
    }
]