//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"



//auth
export const COMPANY_ADMIN_LOGIN = '/auth/company'
export const STAFF_LOGIN = '/auth/staff'
export const MFA_LOGIN = '/auth/mfa'
export const MFA_RESEND = '/auth/mfa-resend'


//companies
export const GET_COMPANIES = '/companies'
export const GET_COMPANY_BY_ID = '/companies'
export const ADD_COMPANY = '/companies'
export const DEL_COMPANY = '/companies/'
export const UPDATE_COMPANY = '/companies'

//SITES
export const GET_SITES_BY_COMPANYCODE = '/sites/companyCode'
export const ADD_SITE = '/sites'
export const GET_SITE_BY_DOCID = '/sites'
export const GET_SITE_BY_SITECODE = '/sites/siteCode'
export const UPDATE_SITE_BY_DOCID = '/sites'
export const DEL_SITE_BY_SITECODE = '/sites/delete-site'

//BOOKING TOOL
export const SAVE_BOOKING_TOOL_SETTING = '/sites/booking-tool/add'
export const GET_BOOKING_TOOL_DATA_BY_SITECODE = '/sites/booking-tool/siteCode'

//directory
export const GET_DIRECTORY_BY_SITECODE = '/sites/directories/siteCode'
export const GET_SITES_BY_SERVICE = '/sites/service'
export const UPDATE_DIR_STATUS_BY_SERVICE = '/sites/directories/update-service'
export const GET_DIRECTORIES_BY_SITE = "/directories";
export const UPDATE_DIRECTORIES_BY_SITE = "/directories"


//admins
export const GET_ADMINS_BY_SITECODE = '/admins/siteCode'
export const GET_ADMINS_BY_COMPANYCODE = '/admins/companyCode'
export const ADD_ADMIN = '/admins'
export const ADD_ADMINS = '/admins/multiple'
export const GET_ADMIN_BY_DOCID = '/admins'
export const UPDATE_ADMIN_BY_DOCID = '/admins'
export const DEL_ADMIN_BY_DOCID = '/admins'

// analytics
export const GET_ANALYTICS_FROM_FIREBASE = '/analytics/fromFirebase';
export const GET_ANALYTICS_CUSTOMERS_VEHICLES = '/analytics/customerAndVehicles';


//ADMIN SCORES
export const GET_SCORES_BY_COMPANYCODE = '/admins/scores/company-code'

//CUSTOMERS
export const GET_CUSTOMERS_BY_SITECODE = '/customers/siteCode'
export const GET_CUSTOMERS_BY_COMPANYCODE = '/customers/companyCode'
export const GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE = '/customers/customersAndVehicles'
export const ADD_CUSTOMER = '/customers'
export const GET_CUSTOMER_BY_DOCID = '/customers'
export const UPDATE_CUSTOMER_BY_DOCID = '/customers'
export const DEL_CUSTOMER_BY_DOCID = '/customers'
export const GET_GRAPH_DATA_OF_CUSTOMER = '/bookings/graph-data'
export const INVITE_CUSTOMER = '/admins/invite-customer'
export const GET_CUSTOMERS_BY_FILTER = '/customers/filter'
export const GET_CUSTOMERS_BY_FILTER_COUNT = '/customers/filter-count'

//VEHICLES
export const GET_ALL_VEHICLES = '/vehicles/all'
export const ADD_VEHICLE = '/vehicles'
export const GET_VEHICLE_BY_DOCID = '/vehicles'
export const DELETE_VEHICLE_BYID = '/vehicles'
export const GET_VEHICLES_BY_CUSTOMERID = '/vehicles/customer'
export const GET_VEHICLE_BY_REGNO = '/vehicles/smartVehicleInfo'

//BOOKINGS
export const GET_ALL_BOOKINGS = '/bookings'
export const GET_BOOKINGS_BY_COMPANYCODE = '/bookings/companyCode'
export const GET_BOOKINGS_BY_SITECODE = '/bookings/siteCode'
export const ADD_BOOKING = '/bookings'
export const GET_BOOKING_BY_DOCID = '/bookings'
export const DELETE_BOOKING_BYID = '/bookings'
export const GET_GRAPH_DATA_OF_BOOKING = '/bookings/graph-data'

//PRODUCTS
export const GET_PRODUCTS_BY_SITECODE = '/products/siteCode'
export const GET_ALL_PRODUCTS = '/products'
export const GET_PRODUCT_BYID = '/products'
export const ADD_PRODUCT = '/products'
export const DELETE_PRODUCT_BYID = '/products'
export const UPDATE_PRODUCT_BY_DOCID = '/products'
export const CREATE_PRODUCT_NOTIFICATIONS = '/products/product-notification'
export const GET_NOTIFICATIONS_BY_PRODUCT = '/products/get-product-notification'
export const CREATE_PRODUCT_SALES = '/product-sales'
export const INVITED_CUSTOMER_PRODUCTION_NOTIFICATION = '/products/invite-product-notification'
export const GET_ALL_DEALER_PRODUCTS = '/was-service/get-wasproducts'
export const CREATE_CONTRACT_DATA = '/was-service/create-contract'
export const GET_CONTRACT_BY_AGREEMENT = '/was-service/get-contract-by-agreement'
export const REFRESH_WAS_PRODUCTS = '/products/refresh-wasproducts'

export const SETUP_WAS_DIRECT_PAYMENT = '/api/click/directdebit?contractnumber='
export const MAKE_CONTRACT_LIVE = '/api/contracts/createcontractstepsix'
export const GET_WAS_CONTRACT_PDF = '/api/click/ContractAgreementDocument?contractNumber='

export const UPDATE_CONTRACT_NUMBER = '/sale/product/updateContractNumber'
export const RECEIVED_DOCUMENT = '/notifications/received-document'



//GEOFENCE
export const ADD_GEO_FENCE = '/geo-fence'
export const GET_ALL_GEOFENCE_LIST = '/geo-fence'
export const GET_GEOFENCE_BYID = '/geo-fence'
export const UPDATE_GEOFENCE_BY_DOCID = '/geo-fence'
export const DELETE_GEOFENCE_BYID = '/geo-fence'

//WARRANTY
export const ADD_WARRANTY = '/warranty'
export const GET_ALL_WARRANTY_LIST = '/warranty'
export const GET_WARRANTY_BYID = '/warranty'
export const UPDATE_WARRANTY_BY_DOCID = '/warranty'
export const DELETE_WARRANTY_BYID = '/warranty'

//COMMS
export const GET_COMMS_BY_SITECODE = '/comms/siteCode'
export const SAVE_COMMS = '/comms/add'

//ROLES
export const GET_ALL_ROLES = '/roles'
export const ADD_ROLE = '/roles'
export const GET_ROLE_BY_ID = '/roles'
export const DELETE_ROLE_BY_ID = '/roles'
export const UPDATE_ROLE_BY_ID = "/roles"

//SALEOFPRODUCTS
export const GET_ALL_SALEOFPRODUCTS = '/sale/product'
export const ADD_SALEOFPRODUCT = '/sale/product'

//NOTIFICATIONS

export const GET_NOTIFICATIONS_BY_CUSTOMERID = '/notifications/customer'
export const GET_NOTIFICATIONS_BY_COMPANYCODE = '/notifications/company'
export const GET_NOTIFICATIONS_BY_SITECODE = '/notifications/site'
export const GET_NOTIFICATIONS_BY_ID = '/notifications'
export const ADD_NOTIFICATION = '/notifications'
export const UPDATE_NOTIFICATION_BY_DOCID = '/notifications'
export const DELETE_NOTIFICATION_BYID = '/notifications'

//NOTIFICATION-TEMPLATE

export const GET_NOTIFICATION_TEMPLATES_BY_COMPANYCODE = '/notification-templates/company'
export const GET_NOTIFICATION_TEMPLATE_BY_ID = '/notification-templates'
export const ADD_NOTIFICATION_TEMPLATE = '/notification-templates'
export const UPDATE_NOTIFICATION_TEMPLATE_BYID = '/notification-templates'
export const DELETE_NOTIFICATION_TMPLATE_BYID = '/notification-templates'
export const CREATE_NOTIFICATIONS = '/notifications/sendTemplate'
export const CREATE_NOTIFICATIONS_BY_FILTER = '/notifications/sendTemplateByFilter'

//GROUPS
export const GET_GROUPS_BY_COMPANYCODE = '/groups/companyCode'
export const GET_ALL_GROUPS = '/groups'
export const GET_GROUP_BY_ID = '/groups'
export const ADD_GROUP = '/groups'
export const UPDATE_GROUP_BY_ID = '/groups'
export const DELETE_GROUP_BY_ID = '/groups'

//SETTINGS
export const GET_ALL_ONBOARDINGS = '/settings/onboarding'
export const ADD_ONBOARDING = '/settings/onboarding'
export const GET_HELP_VIDEO_URL = '/settings/help-video-url'

//DEALERS
export const GET_ALL_DEALERS = '/rtc-services/get-dealers'

export const GET_ME = '/admins/me'

//CLAIMS
export const FETCH_CLAIMS_BY_SITE_CODE = '/claims/site'
export const FETCH_CLAIMS_BY_COMPANY_CODE = '/claims/company'
export const GET_CLAIMS_BY_ID = '/claims'
export const MAKE_CLAIM = '/claims/mark'
export const SEND_MESSAGE = '/claims/message'

//RECOGNITIONS
export const FETCH_ALL_POSTS = '/recognition/posts'
export const FETCH_POSTS_BY_ADMIN_ID = '/recognition/posts/staff'
export const FETCH_POST_BY_ID = '/recognition/posts'