import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags"
import usePosts from "src/hooks/usePosts";
import { IPost } from "src/interfaces/general";
import useAdmins from "src/hooks/useAdmins";
const AdminRecognitionPostDetail = (props: any) => {
  const { postId, adminId } = useParams<any>();
  const { getPostByPostId } = usePosts();
  const { singleAdmin, fetchAdminById } = useAdmins();
  const [currentPost, setCurrentPost] = useState<IPost | undefined>(undefined);
  const formatDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  const renderAssets = (assets: Array<{ url: string; type: "video" | "image" }>) => {
    return (
      <Row className="mb-4">
        <Col md="12">
          <h5 className="mb-3">Media Assets</h5>
          <div className="d-flex flex-wrap gap-3">
            {assets.map((asset, index) => (
              <div key={index} className="border rounded p-2" style={{ maxWidth: '200px' }}>
                {asset.type === 'image' ? (
                  <img
                    src={asset.url}
                    alt={`Asset ${index + 1}`}
                    className="img-fluid rounded"
                    style={{ height: '150px', objectFit: 'cover' }}
                  />
                ) : (
                  <video
                    src={asset.url}
                    controls
                    className="rounded"
                    style={{ height: '150px', width: '100%' }}
                  />
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    );
  };
  const renderComments = (comments: Array<{
    id?: string;
    text: string;
    user: { id: string; name: string; avatar: string };
    createdAt: number;
  }>) => {
    return (
      <Row className="mb-4">
        <Col md="12">
          <Card>
            <CardBody>
              <h5 className="mb-4">Comments ({comments.length})</h5>
              <div className="d-flex flex-column gap-4">
                {comments.map((comment, index) => (
                  <div key={index} className="d-flex border-bottom pb-3">
                    <div className="me-3">
                      <img
                        src={comment.user.avatar || '/default-avatar.png'}
                        alt={comment.user.name}
                        className="rounded-circle"
                        width="40"
                        height="40"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-start">
                        <h6 className="mb-1">{comment.user.name}</h6>
                        <small className="text-muted">
                          {formatDate(comment.createdAt)}
                        </small>
                      </div>
                      <p className="mb-0 text-muted">{comment.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const post = await getPostByPostId(postId);
        console.log('post:', post);
        if (post) {
          setCurrentPost(post);
        } else {
          console.log('No post found');
        }
      } catch (error) {
        const errorMsg = error instanceof Error ? error.message : 'Unknown error occurred';
        console.error('Error fetching post:', errorMsg);
      }
    };

    fetchPost();
    fetchAdminById(adminId);
  }, [postId]);
  const handleEditSite = (values: any) => {
    // dispatch(updateSiteByDocId(docid, values));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Post</title>
      </MetaTags>

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to={`/admins/recognitions/${adminId}`} className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              {singleAdmin?.firstName || '' + singleAdmin?.lastName || ''} Post Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              ID: {postId}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  {currentPost && (
                    <Row className="mt-4">
                      <Col md="12">
                        <Card>
                          <CardBody>
                            <h4 className="card-title mb-4">{currentPost.title}</h4>

                            <div className="mb-4">
                              <p className="text-muted">{currentPost.content}</p>
                            </div>

                            <div className="d-flex mb-4">
                              <div className="me-4">
                                <p className="mb-0"><strong>Created:</strong></p>
                                <p className="text-muted">
                                  {formatDate(currentPost.createdAt)}
                                </p>
                              </div>
                            </div>

                            <Row>
                              <Col md="3">
                                <div className="text-center border p-3 rounded">
                                  <h5>{currentPost.likes?.length || 0}</h5>
                                  <p className="mb-0">Likes</p>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="text-center border p-3 rounded">
                                  <h5>{currentPost.comments?.length || 0}</h5>
                                  <p className="mb-0">Comments</p>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="text-center border p-3 rounded">
                                  <h5>{currentPost.shares?.length || 0}</h5>
                                  <p className="mb-0">Shares</p>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="text-center border p-3 rounded">
                                  <h5>{currentPost.pins?.length || 0}</h5>
                                  <p className="mb-0">Pins</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}

                  {currentPost && (
                    <Row className="">
                      <Col md="12">
                        <Card>
                          <CardBody>
                            {/* ...existing title and content... */}

                            {/* Add Assets Section */}
                            {currentPost.assets && currentPost.assets.length > 0 && (
                              renderAssets(currentPost.assets)
                            )}

                            {/* ...existing creation date and stats... */}

                            {/* Add Comments Section */}
                            {currentPost.comments && currentPost.comments.length > 0 && (
                              renderComments(currentPost.comments)
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminRecognitionPostDetail);