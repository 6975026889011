import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row, CardTitle, FormGroup, Label, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addSite } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import AddAvatar from "src/components/Common/AddAvatar";
import ColorInput from "src/components/Common/ColorInput";
import Dropzone from "react-dropzone";
import { storage } from "../../helpers/firebase_config";

const AddSite = (props: any) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    logo: "",
    home_video_url: "",
    color: "",
    buy: false,
    sell_my_car: false,
    insurance: false,
    rental: false,
    tyres: false,
    parts: false,
    parking: false,
    fix_my_car: false,
    charge_points: false,
    my_carbon: false,
    games: false,
    finance: false,
    car_warranty: false,
    gap_insurance: false,
    service_plan: false,
    protect: false,
  });

  const { currentUser } = useSelector((state: any) => state.login);
  const { loading, success } = useSelector((state: any) => state.site);
  const [videoUploading, setVideoUploading] = useState<boolean>(false);
  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddSite = (values: any) => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      values.companyCode = currentUser.code;
    } else {
      values.companyCode = currentUser.companyCode;
    }
    values.logo = formData.logo;
    values.color = formData.color;
    values.home_video_url = formData.home_video_url;

    const services = {
      buy: formData.buy,
      sell_my_car: formData.sell_my_car,
      insurance: formData.insurance,
      rental: formData.rental,
      tyres: formData.tyres,
      parts: formData.parts,
      parking: formData.parking,
      fix_my_car: formData.fix_my_car,
      charge_points: formData.charge_points,
      my_carbon: formData.my_carbon,
      games: formData.games,
      finance: formData.finance,
      car_warranty: formData.car_warranty,
      gap_insurance: formData.gap_insurance,
      service_plan: formData.service_plan,
      protect: formData.protect,
    };

    values.services = services;
    dispatch(addSite(values));
  };

  const handleDiretoryCheck = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const onChange = (url: string) => setFormData({ ...formData, logo: url });

  const onChangeColor = (color: string) => {
    setFormData({ ...formData, color: color });
  };

  const handleAcceptedVideoFiles = async (files: any) => {
    setVideoUploading(true);
    const path = `/home_video_url/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setVideoUploading(false);
    setFormData({ ...formData, home_video_url: url });
  };

  const handleChangeVideo = (e: any) =>{
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Site</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-new-site-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddSite(values);
            }}
          >
            <div className="add-site-form-cotainer">
              <AddAvatar onChange={onChange} title={"Site"} />
              <Row>
                <Col md="12">
                  <CardTitle>Home video URL</CardTitle>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedVideoFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h6>Drop files here or click to upload.</h6>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews" id="file-previews">
                    {videoUploading && (
                      <div className="d-flex justify-content-center">
                        <i
                          className="bx bx-loader bx-spin align-middle me-2"
                          style={{ fontSize: 46 }}
                        ></i>
                      </div>
                    )}
                    <div className="pt-3 pb-3">
                      <FormGroup>
                        <Label for="image">Or Please enter video URL.</Label>
                        <Input
                          name="home_video_url"
                          onChange={handleChangeVideo}
                          value={formData.home_video_url}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="code"
                      label="Site Code"
                      placeholder="site code should 7 digits"
                      type="string"
                      errorMessage="Should be 7 digits"
                      validate={{
                        required: { value: true },
                        minLength: {
                          value: 7,
                          errorMessage: "Min 7 chars.",
                        },
                        maxLength: {
                          value: 7,
                          errorMessage: "Max 7 chars.",
                        },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Name"
                      type="text"
                      errorMessage="Invalid Name"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="phone"
                      label="Phone"
                      type="text"
                      errorMessage="Invalid Phone number"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      type="email"
                      errorMessage="Invalid Email"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      errorMessage="Invalid Password"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="address"
                      label="Address"
                      type="text"
                      errorMessage="Invalid Address"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="postcode"
                      label="Postcode"
                      type="text"
                      errorMessage="Invalid postcode"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="websiteUrl"
                      label="Website Url"
                      type="text"
                      errorMessage="Invalid websiteUrl"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="bookingUrl"
                      label="Booking Url"
                      type="text"
                      errorMessage="Invalid bookingUrl"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="tutorialVideo"
                      label="Tutorial Video"
                      type="text"
                      errorMessage="Invalid tutorialVideo"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Row>
                  <Col md="6">
                    <h5>Location</h5>
                  </Col>
                </Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="location.lat"
                      label="Lat"
                      type="number"
                      errorMessage="Invalid lat"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="location.lon"
                      label="Lon"
                      type="number"
                      errorMessage="Invalid lon"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="dealerId"
                      label="WAS Dealer ID"
                      type="text"
                      errorMessage="Invalid WAS Dealer ID"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="managerName"
                      label="Manager Name"
                      type="text"
                      errorMessage="Invalid ManagerName"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="spincarId"
                      label="Spincar ID"
                      type="text"
                      errorMessage="Invalid Spincar ID"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <label> Color </label>
                    <ColorInput onChangeColor={onChangeColor} />
                  </div>
                </Col>
              </Row>

              <Row className="mt-4 directory-container">
                <h4>Directories</h4>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="buy"
                    checked={formData.buy}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Buy</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="sell_my_car"
                    checked={formData.sell_my_car}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Sell My Car</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="insurance"
                    checked={formData.insurance}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Insurance</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="rental"
                    checked={formData.rental}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Rental</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="tyres"
                    checked={formData.tyres}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Tyres</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="parts"
                    checked={formData.parts}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Parts</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="parking"
                    checked={formData.parking}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Parking</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="fix_my_car"
                    checked={formData.fix_my_car}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Fix My Car</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="charge_points"
                    checked={formData.charge_points}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Charge Points</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="my_carbon"
                    checked={formData.my_carbon}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">My Carbon</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="games"
                    checked={formData.games}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Games</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="finance"
                    checked={formData.finance}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Finance</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="car_warranty"
                    checked={formData.car_warranty}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Car Warranty</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="gap_insurance"
                    checked={formData.gap_insurance}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Gap Insurance</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="service_plan"
                    checked={formData.service_plan}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Service Plan</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="protect"
                    checked={formData.protect}
                    onChange={e => handleDiretoryCheck(e)}
                  />
                  <label className="form-check-label">Protect</label>
                </div>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-success save-user"
                      disabled={loading}
                    >
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddSite);
