import React, { useState, useRef, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { makeColumns } from "src/components/Common/TablePage";
//redux
import { recognitionListInfoPosts } from "./recognitionListInfoPosts";
import usePosts from "src/hooks/usePosts";
import { IAdmin } from "src/interfaces/general";
import { useParams } from "react-router";
import { post } from "src/helpers/api_helper";
import useAdmins from "src/hooks/useAdmins";
const AdminRecognitionDetail = () => {
  const { adminId } = useParams<{ adminId: string }>();
  const { fetchPostsByAdminId, posts, loading } = usePosts();
  const { singleAdmin, fetchAdminById } = useAdmins();
  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 10,
    totalSize: posts.length, // replace later with size(companies),
    custom: true,
  };
  const history = useHistory();

  const defaultSorted: any = [
    {
      dataField: "siteCode", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const onEdit = (post: any) => {
    history.push(`/admins/recognitions/${adminId}/${post.id}`);
  };


  useEffect(() => {
    fetchPostsByAdminId(adminId);
    fetchAdminById(adminId);
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Posts </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{ fontSize: 46 }}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={posts}
                          columns={makeColumns(null, onEdit, recognitionListInfoPosts)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        {singleAdmin?.firstName || '' + singleAdmin?.lastName || ''} Recognition List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({posts.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                                </div>

                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      // selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    // responsive
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminRecognitionDetail);
